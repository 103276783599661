import QuizSteps from 'core/common/components/QuizSteps';
import { LoadingStatuses } from 'core/common/entities';
import { FeatureFlagsConfig } from 'core/feature-flags/entities';
import { FunnelProvider, FlowProvider } from 'core/funnel/contexts';
import { FunnelConfig } from 'core/funnel/entities';
import { useFunnelConfig } from 'core/funnel/hooks';

export type FunnelProps = {
  pageId: string;
  funnelId: string;
  features: FeatureFlagsConfig['features'];
  funnelConfig: FunnelConfig | null;
};

const Funnel = ({ pageId, funnelId, features, funnelConfig }: FunnelProps) => {
  const { config, loadingStatus } = useFunnelConfig(funnelConfig, funnelId);

  if (loadingStatus === LoadingStatuses.PENDING || !config) {
    // TODO: add page loader
    return null;
  }

  return (
    <FunnelProvider resources={config} features={features}>
      <FlowProvider>
        <QuizSteps pageId={pageId} />
      </FlowProvider>
    </FunnelProvider>
  );
};

export default Funnel;
