import { appnebulaConfig } from 'src/app/appnebulaConfig';
import { buildGrowthBookFeatureFlagsInstance } from 'core/feature-flags/utils';
import { FunnelServiceBuilder } from 'core/funnel/builders';
import { StaticProps } from 'core/funnel/entities';

export { default } from 'core/funnel/pages';

export const getStaticProps = async (context: StaticProps) => {
  const funnelService = FunnelServiceBuilder.create(appnebulaConfig);
  const growthBookFeatureFlags = buildGrowthBookFeatureFlagsInstance();

  const [funnels] = await Promise.all([
    funnelService.getFunnels({
      funnelName: context.params.funnel,
      language: context.locale,
    }),
    growthBookFeatureFlags.loadFeatures(),
  ]);

  if (!funnels || !funnels.length) {
    return {
      props: {
        // Unknown page id is used below to redirect user to initial screen of active flow
        pageId: 'unknown',
        funnel: context.params.funnel,
        features: growthBookFeatureFlags.getFeatures(),
        funnelConfig: null,
      },
    };
  }

  const currentFunnel = funnels[0];

  const analytics = currentFunnel?.analytics.length ? currentFunnel.analytics : [];

  return {
    props: {
      // Unknown page id is used below to redirect user to initial screen of active flow
      pageId: 'unknown',
      funnel: context.params.funnel,
      features: growthBookFeatureFlags.getFeatures(),
      funnelConfig: currentFunnel,
      analytics,
    },
  };
};

export async function getStaticPaths() {
  const funnelService = FunnelServiceBuilder.create(appnebulaConfig);

  const funnels = await funnelService.getFunnels();

  if (!funnels || !funnels.length) {
    return { paths: [], fallback: 'blocking' };
  }

  const paths = funnelService.getFunnelsPaths(funnels);

  return { paths, fallback: 'blocking' };
}
